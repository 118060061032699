<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import PeriodicTable from '@/components/Fantasy/periodicTable'
import ScratchElement from '@/components/Fantasy/scratchable'
import ElementLogs from '@/components/Fantasy/elementsLogs'
import userService from '../../../helpers/userService';
import errorHandler from '../../../helpers/errorHandler';

/**
 * Starter component
 */
export default {
  async created(){
    await this.$store.dispatch('fantasy/getMyChances',userService.methods.userID()).then(()=>{}).catch(err =>{
      console.log('No chances')
    })
    await this.$store.dispatch('fantasy/getMyElements',userService.methods.userID()).then(()=>{}).catch(err =>{
      console.log('No Elements')
    })
  },
  data() {
    return {
      title: "Fantasy",
      items: [
        {
          text: "Dr.Micheal Labib",
          href: "/",
        },
        {
          text: "Fantasy",
          href: "/my-fantasy",
        },
        {
          text: "fantasy",
          active: true,
        },
      ],
    };
  },
  computed:{
    chances(){
      return this.$store.getters['fantasy/allChances'];
    },
    elements(){
      return this.$store.getters['fantasy/allElements'];
    }
  },
  methods:{
    async useChance(){
      await this.$store.dispatch('fantasy/useChance',userService.methods.userID()).then(async (res)=>{
        this.showModal();
        await this.$store.dispatch('fantasy/getMyElements',userService.methods.userID())
      }).catch( err =>{
        this.$store.dispatch('fantasy/getMyChances',userService.methods.userID())
        errorHandler.methods.errorMessage(err);
      })
    },
     showModal() {
      this.$bvModal.show('scratchElement')
    },
  },
  components: {
    Layout,
    PageHeader,
    PeriodicTable,
    ScratchElement,
    ElementLogs,
  },
};
</script>

<template>
  <Layout>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet"> 
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <h1 class="boujee-text text-center" style="padding-bottom: 2.5rem !important;">Chemistry Fantasy</h1>
      </div>
      <div class="col-12 col-md-3" style="text-align: center">
        <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-lg h-md-250 position-relative" style="background-color: #fafafa;">
          <div class="col p-3 d-flex flex-column position-static">
            <h3 class="mb-1">My chances <span class="badge bg-success"> {{ chances }} </span></h3>
            <p class="card-text mb-1">Use chance to get new elements.</p>
            <b-button variant="warning" pill style="max-width: 10rem;" :disabled="chances == 0" @click="useChance">Use chance</b-button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-lg h-md-250 position-relative" style="background-color: #fafafa;">
          <div class="col p-3 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-success">My Fantasy elements</strong>
            <h3 class="mb-1">Elements: <span class="badge bg-success">{{ elements.length }}</span></h3>
            <p class="card-text mb-1">Use chance to get new elements.</p>
            <!-- <b-button variant="success" pill class="btn btn-block" @click="addElement">Add element</b-button> -->
          </div>
        </div>
      </div>
    </div>
    <PeriodicTable :elements="elements"/>
    <ElementLogs :elements="elements" />
    <b-modal id="scratchElement" size="md" title="Scratch element !">
        <ScratchElement />
        <template #modal-footer>
          <b-button variant="outline-success" @click="$bvModal.hide('scratchElement')">Done</b-button>
        </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.boujee-text {
  --bg-size: 400%;
  --color-one: #42d392;
  --color-two: #647eff;
  font-family: 'Pacifico', cursive;
  font-size: clamp(2rem, 15vmin, 5rem);
  background: linear-gradient(
      90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

</style>