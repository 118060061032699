<template>
    <div>
      <h2 class="text-center">Congrats 🎉🎉 you won a new element 💚.</h2>
      <h4 class="text-center">Scratch the element!</h4>
      <vue-scratchable
        v-slot="{ init }"
        :brushOptions="brush"
        :hideOptions="hide"
        style="max-width: 320px;max-height: 320px;"
      >
        <div class="wrapper" style="max-width: 300;max-height: 300;">
          <img
            :src="getURL(element.name)"
            @load="init()"
            style="max-width: 250px;max-height: 250px;"
          >
          <h4> Congrats you won {{ element.name }} 🎉🎉</h4>
        </div>
      </vue-scratchable>
    </div>
</template>

<script>
import VueScratchable from 'vue-scratchable';
import imagesJson from './images.json';

export default {
name: 'scratchable',
components: {
    VueScratchable,
},
data() {
    return {
    images: imagesJson,
    percentage: 0,
    hide: {
        type: 'color',
        value: '#a1a1a1',
    },
    brush: {
        size: 60,
        shape: 'round',
    },
    };
},
methods: {
    updatePoints(value) {
        this.percentage = value;
    },
    getURL(val){
      let index = imagesJson.findIndex( item => item.name == val)
      return imagesJson[index].image.url;
    }
},
computed:{
    element(){
        return this.$store.getters['fantasy/singleElement'];
    }
}
};
</script>
  