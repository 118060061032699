<template>
  <div class="row">
    <div class="container overflow-auto">
      <transition name="fade">
        <div class="overlay" v-if="overlay" @click="hideOverlay"></div>
      </transition>
      <transition name="fade">
        <div class="wholeContainer" style="margin-top: 17rem;" v-if="overlay">
          <span class="name" v-bind:class="selectedSquare.groupBlock">{{selectedSquare.name}} [{{selectedSquare.symbol}}], {{selectedSquare.atomicNumber}}</span>
          <span class="yearDiscovered" v-if="selectedSquare.yearDiscovered" v-bind:class="selectedSquare.groupBlock">Discovered in {{selectedSquare.yearDiscovered}}</span>
          <div class="detailsView">
            <div class="electrons" v-for="electron in 3"></div>
            <div class="selectedSquare"v-bind:class="selectedSquare.groupBlock">&nbsp    
            </div>
          </div>
        </div>
      </transition>
      
      <div class="squareContainer" v-bind:class="{blurred: overlay}">
        <div class="square" v-for="square in squares" :key="square.atomicNumber" v-bind:class="(elements.find(element => element.name == square.name))?square.groupBlock+'-active':square.groupBlock" @click="showDetails(square)">
          <span class="atomicNumber">{{square.atomicNumber}}</span>
          <span class="symbol" style="font-weight: 800;">{{square.symbol}}</span>
          <span class="name">{{square.name}}</span>
          <!-- <span class="atomicMass">{{square.atomicMass}}</span> -->
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      squares: [
        {symbol: "IA"},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {symbol: "VIIIA"},
        {atomicNumber: "1", symbol: "H", name: "Hydrogen", atomicMass: "1.00794(4)", groupBlock: "nonmetal", yearDiscovered: "1766"},
        {symbol:"IIA"},{},{},{},{},{},{},{},{},{},{},{symbol: "IIIA"},{symbol: "IVA"},{symbol: "VA"},{symbol: "VIA"},{symbol: "VIIA"},
        {atomicNumber: "2", symbol: "He", name: "Helium", atomicMass: "4.002602(2)", groupBlock: "nobleGas", yearDiscovered: "1868"},
        {atomicNumber: "3", symbol: "Li", name: "Lithium", atomicMass: "6.941(2)", groupBlock: "alkaliMetal", yearDiscovered: "1817"},
        {atomicNumber: "4", symbol: "Be", name: "Beryllium", atomicMass: "9.012182(3)", groupBlock: "alkalineEarthMetal", yearDiscovered: "1798"},
        {},{},{},{},{},{},{},{},{},{},
        {atomicNumber: "5", symbol: "B", name: "Boron", atomicMass: "10.811(7)", groupBlock: "metalloid", yearDiscovered: "1807"},
        {atomicNumber: "6", symbol: "C", name: "Carbon", atomicMass: "12.0107(8)", groupBlock: "nonmetal", yearDiscovered: ""},
        {atomicNumber: "7", symbol: "N", name: "Nitrogen", atomicMass: "14.0067(2)", groupBlock: "nonmetal", yearDiscovered: "1772"},
        {atomicNumber: "8", symbol: "O", name: "Oxygen", atomicMass: "15.9994(3)", groupBlock: "nonmetal", yearDiscovered: "1774"},
        {atomicNumber: "9", symbol: "F", name: "Fluorine", atomicMass: "18.9984032(5)", groupBlock: "halogen", yearDiscovered: "1670"},
        {atomicNumber: "10", symbol: "Ne", name: "Neon", atomicMass: "20.1797(6)", groupBlock: "nobleGas", yearDiscovered: "1898"},
        {atomicNumber: "11", symbol: "Na", name: "Sodium", atomicMass: "22.98976928(2)", groupBlock: "alkaliMetal", yearDiscovered: "1807"},
        {atomicNumber: "12", symbol: "Mg", name: "Magnesium", atomicMass: "24.3050(6)", groupBlock: "alkalineEarthMetal", yearDiscovered: "1808"},
        {symbol: "IIIB"},{symbol: "IVB"},{symbol: "VB"},{symbol: "VIB"},{symbol: "VIIB"},{symbol: "VIIIB"},{symbol: "IIIB"},{symbol: "IIIB"},{symbol: "IB"},{symbol: "IIB"},
        {atomicNumber: "13", symbol: "Al", name: "Aluminum", atomicMass: "26.9815386(8)", groupBlock: "metal", yearDiscovered: ""},
        {atomicNumber: "14", symbol: "Si", name: "Silicon", atomicMass: "28.0855(3)", groupBlock: "metalloid", yearDiscovered: "1854"},
        {atomicNumber: "15", symbol: "P", name: "Phosphorus", atomicMass: "30.973762(2)", groupBlock: "nonmetal", yearDiscovered: "1669"},
        {atomicNumber: "16", symbol: "S", name: "Sulfur", atomicMass: "32.065(5)", groupBlock: "nonmetal", yearDiscovered: ""},
        {atomicNumber: "17", symbol: "Cl", name: "Chlorine", atomicMass: "35.453(2)", groupBlock: "halogen", yearDiscovered: "1774"},
        {atomicNumber: "18", symbol: "Ar", name: "Argon", atomicMass: "39.948(1)", groupBlock: "nobleGas", yearDiscovered: "1894"},
        {atomicNumber: "19", symbol: "K", name: "Potassium", atomicMass: "39.0983(1)", groupBlock: "alkaliMetal", yearDiscovered: "1807"},
        {atomicNumber: "20", symbol: "Ca", name: "Calcium", atomicMass: "40.078(4)", groupBlock: "alkalineEarthMetal", yearDiscovered: ""},
        {atomicNumber: "21", symbol: "Sc", name: "Scandium", atomicMass: "44.955912(6)", groupBlock: "transitionMetal", yearDiscovered: "1876"},
        {atomicNumber: "22", symbol: "Ti", name: "Titanium", atomicMass: "47.867(1)", groupBlock: "transitionMetal", yearDiscovered: "1791"},
        {atomicNumber: "23", symbol: "V", name: "Vanadium", atomicMass: "50.9415(1)", groupBlock: "transitionMetal", yearDiscovered: "1803"},
        {atomicNumber: "24", symbol: "Cr", name: "Chromium", atomicMass: "51.9961(6)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "25", symbol: "Mn", name: "Manganese", atomicMass: "54.938045(5)", groupBlock: "transitionMetal", yearDiscovered: "1774"},
        {atomicNumber: "26", symbol: "Fe", name: "Iron", atomicMass: "55.845(2)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "27", symbol: "Co", name: "Cobalt", atomicMass: "58.933195(5)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "28", symbol: "Ni", name: "Nickel", atomicMass: "58.6934(4)", groupBlock: "transitionMetal", yearDiscovered: "1751"},
        {atomicNumber: "29", symbol: "Cu", name: "Copper", atomicMass: "63.546(3)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "30", symbol: "Zn", name: "Zinc", atomicMass: "65.38(2)", groupBlock: "transitionMetal", yearDiscovered: "1746"},
        {atomicNumber: "31", symbol: "Ga", name: "Gallium", atomicMass: "69.723(1)", groupBlock: "metal", yearDiscovered: "1875"},
        {atomicNumber: "32", symbol: "Ge", name: "Germanium", atomicMass: "72.64(1)", groupBlock: "metalloid", yearDiscovered: "1886"},
        {atomicNumber: "33", symbol: "As", name: "Arsenic", atomicMass: "74.92160(2)", groupBlock: "metalloid", yearDiscovered: ""},
        {atomicNumber: "34", symbol: "Se", name: "Selenium", atomicMass: "78.96(3)", groupBlock: "nonmetal", yearDiscovered: "1817"},
        {atomicNumber: "35", symbol: "Br", name: "Bromine", atomicMass: "79.904(1)", groupBlock: "halogen", yearDiscovered: "1826"},
        {atomicNumber: "36", symbol: "Kr", name: "Krypton", atomicMass: "83.798(2)", groupBlock: "nobleGas", yearDiscovered: "1898"},
        {atomicNumber: "37", symbol: "Rb", name: "Rubidium", atomicMass: "85.4678(3)", groupBlock: "alkaliMetal", yearDiscovered: "1861"},
        {atomicNumber: "38", symbol: "Sr", name: "Strontium", atomicMass: "87.62(1)", groupBlock: "alkalineEarthMetal", yearDiscovered: "1790"},
        {atomicNumber: "39", symbol: "Y", name: "Yttrium", atomicMass: "88.90585(2)", groupBlock: "transitionMetal", yearDiscovered: "1794"},
        {atomicNumber: "40", symbol: "Zr", name: "Zirconium", atomicMass: "91.224(2)", groupBlock: "transitionMetal", yearDiscovered: "1789"},
        {atomicNumber: "41", symbol: "Nb", name: "Niobium", atomicMass: "92.90638(2)", groupBlock: "transitionMetal", yearDiscovered: "1801"},
        {atomicNumber: "42", symbol: "Mo", name: "Molybdenum", atomicMass: "95.96(2)", groupBlock: "transitionMetal", yearDiscovered: "1778"},
        {atomicNumber: "43", symbol: "Tc", name: "Technetium", atomicMass: "[98]", groupBlock: "transitionMetal", yearDiscovered: "1937"},
        {atomicNumber: "44", symbol: "Ru", name: "Ruthenium", atomicMass: "101.07(2)", groupBlock: "transitionMetal", yearDiscovered: "1827"},
        {atomicNumber: "45", symbol: "Rh", name: "Rhodium", atomicMass: "102.90550(2)", groupBlock: "transitionMetal", yearDiscovered: "1803"},
        {atomicNumber: "46", symbol: "Pd", name: "Palladium", atomicMass: "106.42(1)", groupBlock: "transitionMetal", yearDiscovered: "1803"},
        {atomicNumber: "47", symbol: "Ag", name: "Silver", atomicMass: "107.8682(2)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "48", symbol: "Cd", name: "Cadmium", atomicMass: "112.411(8)", groupBlock: "transitionMetal", yearDiscovered: "1817"},
        {atomicNumber: "49", symbol: "In", name: "Indium", atomicMass: "114.818(3)", groupBlock: "metal", yearDiscovered: "1863"},
        {atomicNumber: "50", symbol: "Sn", name: "Tin", atomicMass: "118.710(7)", groupBlock: "metal", yearDiscovered: ""},
        {atomicNumber: "51", symbol: "Sb", name: "Antimony", atomicMass: "121.760(1)", groupBlock: "metalloid", yearDiscovered: ""},
        {atomicNumber: "52", symbol: "Te", name: "Tellurium", atomicMass: "127.60(3)", groupBlock: "metalloid", yearDiscovered: "1782"},
        {atomicNumber: "53", symbol: "I", name: "Iodine", atomicMass: "126.90447(3)", groupBlock: "halogen", yearDiscovered: "1811"},
        {atomicNumber: "54", symbol: "Xe", name: "Xenon", atomicMass: "131.293(6)", groupBlock: "nobleGas", yearDiscovered: "1898"},
        {atomicNumber: "55", symbol: "Cs", name: "Cesium", atomicMass: "132.9054519(2)", groupBlock: "alkaliMetal", yearDiscovered: "1860"},
        {atomicNumber: "56", symbol: "Ba", name: "Barium", atomicMass: "137.327(7)", groupBlock: "alkalineEarthMetal", yearDiscovered: "1808"},
        {atomicNumber: "57", symbol: "La", name: "Lanthanum", atomicMass: "138.90547(7)", groupBlock: "transitionMetal", yearDiscovered: "1839"},
        {atomicNumber: "72", symbol: "Hf", name: "Hafnium", atomicMass: "178.49(2)", groupBlock: "transitionMetal", yearDiscovered: "1923"},
        {atomicNumber: "73", symbol: "Ta", name: "Tantalum", atomicMass: "180.94788(2)", groupBlock: "transitionMetal", yearDiscovered: "1802"},
        {atomicNumber: "74", symbol: "W", name: "Tungsten", atomicMass: "183.84(1)", groupBlock: "transitionMetal", yearDiscovered: "1783"},
        {atomicNumber: "75", symbol: "Re", name: "Rhenium", atomicMass: "186.207(1)", groupBlock: "transitionMetal", yearDiscovered: "1925"},
        {atomicNumber: "76", symbol: "Os", name: "Osmium", atomicMass: "190.23(3)", groupBlock: "transitionMetal", yearDiscovered: "1803"},
        {atomicNumber: "77", symbol: "Ir", name: "Iridium", atomicMass: "192.217(3)", groupBlock: "transitionMetal", yearDiscovered: "1803"},
        {atomicNumber: "78", symbol: "Pt", name: "Platinum", atomicMass: "195.084(9)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "79", symbol: "Au", name: "Gold", atomicMass: "196.966569(4)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "80", symbol: "Hg", name: "Mercury", atomicMass: "200.59(2)", groupBlock: "transitionMetal", yearDiscovered: ""},
        {atomicNumber: "81", symbol: "Tl", name: "Thallium", atomicMass: "204.3833(2)", groupBlock: "metal", yearDiscovered: "1861"},
        {atomicNumber: "82", symbol: "Pb", name: "Lead", atomicMass: "207.2(1)", groupBlock: "metal", yearDiscovered: ""},
        {atomicNumber: "83", symbol: "Bi", name: "Bismuth", atomicMass: "208.98040(1)", groupBlock: "metal", yearDiscovered: ""},
        {atomicNumber: "84", symbol: "Po", name: "Polonium", atomicMass: "[209]", groupBlock: "metalloid", yearDiscovered: "1898"},
        {atomicNumber: "85", symbol: "At", name: "Astatine", atomicMass: "[210]", groupBlock: "halogen", yearDiscovered: "1940"},
        {atomicNumber: "86", symbol: "Rn", name: "Radon", atomicMass: "[222]", groupBlock: "nobleGas", yearDiscovered: "1900"},
        {atomicNumber: "87", symbol: "Fr", name: "Francium", atomicMass: "[223]", groupBlock: "alkaliMetal", yearDiscovered: "1939"},
        {atomicNumber: "88", symbol: "Ra", name: "Radium", atomicMass: "[226]", groupBlock: "alkalineEarthMetal", yearDiscovered: "1898"},
        {atomicNumber: "89", symbol: "Ac", name: "Actinium", atomicMass: "[227]", groupBlock: "transitionMetal", yearDiscovered: "1899"},
        {atomicNumber: "104", symbol: "Rf", name: "Rutherfordium", atomicMass: "[267]", groupBlock: "transitionMetal", yearDiscovered: "1969"},
        {atomicNumber: "105", symbol: "Db", name: "Dubnium", atomicMass: "[268]", groupBlock: "transitionMetal", yearDiscovered: "1967"},
        {atomicNumber: "106", symbol: "Sg", name: "Seaborgium", atomicMass: "[271]", groupBlock: "transitionMetal", yearDiscovered: "1974"},
        {atomicNumber: "107", symbol: "Bh", name: "Bohrium", atomicMass: "[272]", groupBlock: "transitionMetal", yearDiscovered: "1976"},
        {atomicNumber: "108", symbol: "Hs", name: "Hassium", atomicMass: "[270]", groupBlock: "transitionMetal", yearDiscovered: "1984"},
        {atomicNumber: "109", symbol: "Mt", name: "Meitnerium", atomicMass: "[276]", groupBlock: "transitionMetal", yearDiscovered: "1982"},
        {atomicNumber: "110", symbol: "Ds", name: "Darmstadtium", atomicMass: "[281]", groupBlock: "transitionMetal", yearDiscovered: "1994"},
        {atomicNumber: "111", symbol: "Rg", name: "Roentgenium", atomicMass: "[280]", groupBlock: "transitionMetal", yearDiscovered: "1994"},
        {atomicNumber: "112", symbol: "Cn", name: "Copernicium", atomicMass: "[285]", groupBlock: "transitionMetal", yearDiscovered: "1996"},
        {atomicNumber: "113", symbol: "Nh", name: "Nihonium", atomicMass: "[284]", groupBlock: "post-transitionMetal", yearDiscovered: "2003"},
        {atomicNumber: "114", symbol: "Fl", name: "Flerovium", atomicMass: "[289]", groupBlock: "post-transitionMetal", yearDiscovered: "1998"},
        {atomicNumber: "115", symbol: "Mc", name: "Moscovium", atomicMass: "[288]", groupBlock: "post-transitionMetal", yearDiscovered: "2003"},
        {atomicNumber: "116", symbol: "Lv", name: "Livermorium", atomicMass: "[293]", groupBlock: "post-transitionMetal", yearDiscovered: "2000"},
        {atomicNumber: "117", symbol: "Ts", name: "Tennessine", atomicMass: "[294]", groupBlock: "post-transitionMetal", yearDiscovered: "2010"},
        {atomicNumber: "118", symbol: "Og", name: "Oganesson", atomicMass: "[294]", groupBlock: "nobleGas", yearDiscovered: "2002"},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {symbol: "Lanth."},
        {atomicNumber: "58", symbol: "Ce", name: "Cerium", atomicMass: "140.116(1)", groupBlock: "lanthanoid", yearDiscovered: "1803"},
        {atomicNumber: "59", symbol: "Pr", name: "Praseodymium", atomicMass: "140.90765(2)", groupBlock: "lanthanoid", yearDiscovered: "1885"},
        {atomicNumber: "60", symbol: "Nd", name: "Neodymium", atomicMass: "144.242(3)", groupBlock: "lanthanoid", yearDiscovered: "1885"},
        {atomicNumber: "61", symbol: "Pm", name: "Promethium", atomicMass: "[145]", groupBlock: "lanthanoid", yearDiscovered: "1947"},
        {atomicNumber: "62", symbol: "Sm", name: "Samarium", atomicMass: "150.36(2)", groupBlock: "lanthanoid", yearDiscovered: "1853"},
        {atomicNumber: "63", symbol: "Eu", name: "Europium", atomicMass: "151.964(1)", groupBlock: "lanthanoid", yearDiscovered: "1901"},
        {atomicNumber: "64", symbol: "Gd", name: "Gadolinium", atomicMass: "157.25(3)", groupBlock: "lanthanoid", yearDiscovered: "1880"},
        {atomicNumber: "65", symbol: "Tb", name: "Terbium", atomicMass: "158.92535(2)", groupBlock: "lanthanoid", yearDiscovered: "1843"},
        {atomicNumber: "66", symbol: "Dy", name: "Dysprosium", atomicMass: "162.500(1)", groupBlock: "lanthanoid", yearDiscovered: "1886"},
        {atomicNumber: "67", symbol: "Ho", name: "Holmium", atomicMass: "164.93032(2)", groupBlock: "lanthanoid", yearDiscovered: "1878"},
        {atomicNumber: "68", symbol: "Er", name: "Erbium", atomicMass: "167.259(3)", groupBlock: "lanthanoid", yearDiscovered: "1842"},
        {atomicNumber: "69", symbol: "Tm", name: "Thulium", atomicMass: "168.93421(2)", groupBlock: "lanthanoid", yearDiscovered: "1879"},
        {atomicNumber: "70", symbol: "Yb", name: "Ytterbium", atomicMass: "173.054(5)", groupBlock: "lanthanoid", yearDiscovered: "1878"},
        {atomicNumber: "71", symbol: "Lu", name: "Lutetium", atomicMass: "174.9668(1)", groupBlock: "lanthanoid", yearDiscovered: "1907"},
        {},{},{},
        {symbol: "Act."},
        {atomicNumber: "90", symbol: "Th", name: "Thorium", atomicMass: "232.03806(2)", groupBlock: "actinoid", yearDiscovered: "1828"},
        {atomicNumber: "91", symbol: "Pa", name: "Protactinium", atomicMass: "231.03588(2)", groupBlock: "actinoid", yearDiscovered: "1913"},
        {atomicNumber: "92", symbol: "U", name: "Uranium", atomicMass: "238.02891(3)", groupBlock: "actinoid", yearDiscovered: "1789"},
        {atomicNumber: "93", symbol: "Np", name: "Neptunium", atomicMass: "[237]", groupBlock: "actinoid", yearDiscovered: "1940"},
        {atomicNumber: "94", symbol: "Pu", name: "Plutonium", atomicMass: "[244]", groupBlock: "actinoid", yearDiscovered: "1940"},
        {atomicNumber: "95", symbol: "Am", name: "Americium", atomicMass: "[243]", groupBlock: "actinoid", yearDiscovered: "1944"},
        {atomicNumber: "96", symbol: "Cm", name: "Curium", atomicMass: "[247]", groupBlock: "actinoid", yearDiscovered: "1944"},
        {atomicNumber: "97", symbol: "Bk", name: "Berkelium", atomicMass: "[247]", groupBlock: "actinoid", yearDiscovered: "1949"},
        {atomicNumber: "98", symbol: "Cf", name: "Californium", atomicMass: "[251]", groupBlock: "actinoid", yearDiscovered: "1950"},
        {atomicNumber: "99", symbol: "Es", name: "Einsteinium", atomicMass: "[252]", groupBlock: "actinoid", yearDiscovered: "1952"},
        {atomicNumber: "100", symbol: "Fm", name: "Fermium", atomicMass: "[257]", groupBlock: "actinoid", yearDiscovered: "1952"},
        {atomicNumber: "101", symbol: "Md", name: "Mendelevium", atomicMass: "[258]", groupBlock: "actinoid", yearDiscovered: "1955"},
        {atomicNumber: "102", symbol: "No", name: "Nobelium", atomicMass: "[259]", groupBlock: "actinoid", yearDiscovered: "1957"},
        {atomicNumber: "103", symbol: "Lr", name: "Lawrencium", atomicMass: "[262]", groupBlock: "actinoid", yearDiscovered: "1961"}
      ],
      selectedSquare: '',
      overlay: false,
    }
  },
  methods: {
    resizeSquares: function() {
      let squares = document.querySelectorAll(".square");
      squares.forEach(function(element) {
        element.style.height = element.offsetWidth + 'px';
      });
    },
    showDetails: function(square) {
      if(square.name) {
        this.overlay = !this.overlay;
        this.selectedSquare = square;
      }
    },
    hideOverlay: function() {
      this.overlay = !this.overlay;
    }
  },
  mounted() {
    // this.resizeSquares();
  },
  props:{
    elements:{
      type: Array,
      req: true
    }
  }
}
</script>

<style lang="scss" scoped>
.blurred {
  filter: blur(40px);
}
.container {
  display: flex; 
  flex-direction: column;
  align-items: center;
}
.squareContainer {
  display: flex;
  flex-wrap: wrap;
  width: 972px;
  margin: 0 auto;
  transition: all .2s ease;
  .square {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 2px;
    padding: 2px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .5s ease;
    .atomicNumber {
      font-size: 8px;
    }
    .symbol {
      align-self: center;
    }
    .name {
      font-size: 8.5px;
      align-self: center;
    }
    .atomicMass {
      font-size: 8px;
      align-self: center;
    }
  }
}
.nonmetal {
  border: 2px solid #a2d1a7;
  color: #a2d1a7;
}
.nobleGas {
  border: 2px solid #909bd1;
  color: #909bd1;
}
.alkaliMetal {
  border: 2px solid #e8646f;
  color: #e8646f;
}
.alkalineEarthMetal {
  border: 2px solid #ffa121;
  color: #ffa121;
}
.metalloid {
  border: 2px solid #b8b33b;
  color: #b8b33b;
}
.halogen {
  border: 2px solid #2ebfc4;
  color: #2ebfc4;
}
.metal {
  border: 2px solid #67a6d9;
  color: #67a6d9;
}
.post-transitionMetal {
  border: 2px solid #1ABC9C;
  color: #1ABC9C;
}
.transitionMetal {
  border: 2px solid #fbd451;
  color: #fbd451;
}
.lanthanoid {
  border: 2px solid #b36da1;
  color: #b36da1;
}
.actinoid {
  border: 2px solid #da2d99;
  color: #da2d99;
}
.nonmetal-active {
  border: 2px solid #a2d1a7;
  background-color: #a2d1a7;
  color: #010101;
}
.nobleGas-active {
  border: 2px solid #909bd1;
  background-color:  #909bd1;
  color: #010101;
}
.alkaliMetal-active {
  border: 2px solid #e8646f;
  background-color:  #e8646f;
  color: #010101;
}
.alkalineEarthMetal-active {
  border: 2px solid #ffa121;
  background-color:  #ffa121;
  color: #010101;
}
.metalloid-active {
  border: 2px solid #b8b33b;
  background-color:  #b8b33b;
  color: #010101;
}
.halogen-active {
  border: 2px solid #2ebfc4;
  background-color:  #2ebfc4;
  color: #010101;
}
.metal-active {
  border: 2px solid #67a6d9;
  background-color:  #67a6d9;
  color: #010101;
}
.post-transitionMetal-active {
  border: 2px solid #1ABC9C;
  background-color:  #1ABC9C;
  color: #010101;
}
.transitionMetal-active {
  border: 2px solid #fbd451;
  background-color:  #fbd451;
  color: #010101;
}
.lanthanoid-active {
  border: 2px solid #b36da1;
  background-color: #b36da1;
  color: #010101;
}
.actinoid-active {
  border: 2px solid #da2d99;
  background-color:  #da2d99;
  color: #010101;
}

.overlay {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  background: black;
  transition: all .2s ease;
  opacity: .2;
}
.wholeContainer {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 400px;
  & > span {
    border: none;
  }
  & > span:nth-child(1) {
    font-size: 28px;
  } 
}
.detailsView {
  margin-top: 100px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border: 1px solid #000;
  border-radius: 50%;
  animation: rotate 4s linear infinite;
  .electrons {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    background: #000;
    &:nth-child(1) {
      top: calc(75% - 10px);
      left: 0px;
    }
    &:nth-child(2) {
      top: -10px;
      left: calc(50% -10px);
    }
    &:nth-child(3) {
      top: calc(75% - 10px);
      right: 0px;
    }
  }
}
.selectedSquare {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  border-width: 6px;
  transition: all .2s ease;
}
.detailsView > .selectedSquare {
  border: none;
}
.detailsView > .nonmetal {
  background: radial-gradient(circle at 150px 150px, #95A5A6, #000);
}
.detailsView > .nobleGas {
  background: radial-gradient(circle at 150px 150px, #8E44AD, #000);
}
.detailsView > .alkaliMetal {
  background: radial-gradient(circle at 150px 150px, #E74C3C, #000);
}
.detailsView > .alkalineEarthMetal {
  background: radial-gradient(circle at 150px 150px, #E67E22, #000);
}
.detailsView > .metalloid {
  background: radial-gradient(circle at 150px 150px, #3498DB, #000);
}
.detailsView > .halogen {
  background: radial-gradient(circle at 150px 150px, #EC87BF, #000);
}
.detailsView > .metal {
  background: radial-gradient(circle at 150px 150px, #2ECC71, #000);
}
.detailsView > .post-transitionMetal {
  background: radial-gradient(circle at 150px 150px, #1ABC9C, #000);
}
.detailsView > .transitionMetal {
  background: radial-gradient(circle at 150px 150px, #F1C40F, #000);
}
.detailsView > .lanthanoid {
  background: radial-gradient(circle at 150px 150px, #F39C12, #000);
}
.detailsView > .actinoid {
  background: radial-gradient(circle at 150px 150px, #ECF0F1, #000);
}

.legend {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    color: #fff;
  }
  .color {
    height: 14px;
    width: 30px;
    margin: 2px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    top: 0px;
  }
  50% {
    transform: rotate(180deg);
    top: 20px;
  }
  100% {
    transform: rotate(360deg);
    top: 0px;
  }
}

</style>

