<script>
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import moment from 'moment';
import userService from '../../helpers/userService';
import errorHandler from '../../helpers/errorHandler';
import ExportExcel from '../exportExcel.vue';


/**
 * Starter component
 */
export default {
  components: {
    ConfirmActionDialog,
    ExportExcel
  },
  async created() {
    if([0, 3].includes(userService.methods.userRole())){
      this.auth = true;
    }
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    rows() {
      return this.elements.length;
    }
  },
  props:{
    elements:{
        require:true,
        type: Array
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("fantasy/deleteElement",{elementID:this.recordId,studID:this.$route.params.student}).then((token)=>{
      errorHandler.methods.successful('Element deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
            this.$bvModal.show('deleteRecord')
            this.recordId = id
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
  filters:{
    formatDate(value){
    if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
    }
    },
  },
  data: () => ({
    auth: false,    
    totalRows:0,
    recordId: null,
    perPage: 10,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "granted_at",
    sortDesc: true,
    filterOn: ["name","symbol"],
    fields: [
      {
        key: "name",
        label: "Element",
        sortable: true,
        selected: true
      },
      {
        key: "grantee_reason",
        label: "Details",
        sortable: true,
        selected: true
      },
      {
        key: "granted_at",
        label: "Date",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
    <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Element details</h3>
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <export-excel :jsonData="elements" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="elements"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(name)="data">
                <div class="squareContainer  p-0" style="width: 60px;height: 60px;">
                    <div class="square" :class="data.item.group_block+'-active'" style="width: auto;height: auto;">
                        <span class="atomicNumber">{{data.item.atomic_number}}</span>
                        <span class="symbol" style="font-weight: 800;">{{data.item.symbol}}</span>
                        <span class="name">{{data.item.name}}</span>
                    </div>
                </div>
            </template>
            <template v-slot:cell(granted_at)="data">
                {{ data.item.granted_at | formatDate }}
            </template>
          <template v-slot:cell(action)="data" v-if="auth">

                  <b-button
                  variant="danger"
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-1 font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-button>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.blurred {
  filter: blur(40px);
}
.container {
  display: flex; 
  flex-direction: column;
  align-items: center;
}
.squareContainer {
  display: flex;
  flex-wrap: wrap;
  width: 972px;
  margin: 0 auto;
  transition: all .2s ease;
  .square {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 2px;
    padding: 2px;
    overflow: hidden;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .5s ease;
    .atomicNumber {
      font-size: 8px;
    }
    .symbol {
      align-self: center;
    }
    .name {
      font-size: 8.5px;
      align-self: center;
    }
    .atomicMass {
      font-size: 8px;
      align-self: center;
    }
  }
}
.nonmetal {
  border: 2px solid #a2d1a7;
  color: #a2d1a7;
}
.nobleGas {
  border: 2px solid #909bd1;
  color: #909bd1;
}
.alkaliMetal {
  border: 2px solid #e8646f;
  color: #e8646f;
}
.alkalineEarthMetal {
  border: 2px solid #ffa121;
  color: #ffa121;
}
.metalloid {
  border: 2px solid #b8b33b;
  color: #b8b33b;
}
.halogen {
  border: 2px solid #2ebfc4;
  color: #2ebfc4;
}
.metal {
  border: 2px solid #67a6d9;
  color: #67a6d9;
}
.post-transitionMetal {
  border: 2px solid #1ABC9C;
  color: #1ABC9C;
}
.transitionMetal {
  border: 2px solid #fbd451;
  color: #fbd451;
}
.lanthanoid {
  border: 2px solid #b36da1;
  color: #b36da1;
}
.actinoid {
  border: 2px solid #da2d99;
  color: #da2d99;
}
.nonmetal-active {
  border: 2px solid #a2d1a7;
  background-color: #a2d1a7;
  color: #010101;
}
.nobleGas-active {
  border: 2px solid #909bd1;
  background-color:  #909bd1;
  color: #010101;
}
.alkaliMetal-active {
  border: 2px solid #e8646f;
  background-color:  #e8646f;
  color: #010101;
}
.alkalineEarthMetal-active {
  border: 2px solid #ffa121;
  background-color:  #ffa121;
  color: #010101;
}
.metalloid-active {
  border: 2px solid #b8b33b;
  background-color:  #b8b33b;
  color: #010101;
}
.halogen-active {
  border: 2px solid #2ebfc4;
  background-color:  #2ebfc4;
  color: #010101;
}
.metal-active {
  border: 2px solid #67a6d9;
  background-color:  #67a6d9;
  color: #010101;
}
.post-transitionMetal-active {
  border: 2px solid #1ABC9C;
  background-color:  #1ABC9C;
  color: #010101;
}
.transitionMetal-active {
  border: 2px solid #fbd451;
  background-color:  #fbd451;
  color: #010101;
}
.lanthanoid-active {
  border: 2px solid #b36da1;
  background-color: #b36da1;
  color: #010101;
}
.actinoid-active {
  border: 2px solid #da2d99;
  background-color:  #da2d99;
  color: #010101;
}

.overlay {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  background: black;
  transition: all .2s ease;
  opacity: .2;
}
.wholeContainer {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 400px;
  & > span {
    border: none;
  }
  & > span:nth-child(1) {
    font-size: 28px;
  } 
}
.detailsView {
  margin-top: 100px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border: 1px solid #000;
  border-radius: 50%;
  animation: rotate 4s linear infinite;
  .electrons {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    background: #000;
    &:nth-child(1) {
      top: calc(75% - 10px);
      left: 0px;
    }
    &:nth-child(2) {
      top: -10px;
      left: calc(50% -10px);
    }
    &:nth-child(3) {
      top: calc(75% - 10px);
      right: 0px;
    }
  }
}
.selectedSquare {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  border-width: 6px;
  transition: all .2s ease;
}
.detailsView > .selectedSquare {
  border: none;
}
.detailsView > .nonmetal {
  background: radial-gradient(circle at 150px 150px, #95A5A6, #000);
}
.detailsView > .nobleGas {
  background: radial-gradient(circle at 150px 150px, #8E44AD, #000);
}
.detailsView > .alkaliMetal {
  background: radial-gradient(circle at 150px 150px, #E74C3C, #000);
}
.detailsView > .alkalineEarthMetal {
  background: radial-gradient(circle at 150px 150px, #E67E22, #000);
}
.detailsView > .metalloid {
  background: radial-gradient(circle at 150px 150px, #3498DB, #000);
}
.detailsView > .halogen {
  background: radial-gradient(circle at 150px 150px, #EC87BF, #000);
}
.detailsView > .metal {
  background: radial-gradient(circle at 150px 150px, #2ECC71, #000);
}
.detailsView > .post-transitionMetal {
  background: radial-gradient(circle at 150px 150px, #1ABC9C, #000);
}
.detailsView > .transitionMetal {
  background: radial-gradient(circle at 150px 150px, #F1C40F, #000);
}
.detailsView > .lanthanoid {
  background: radial-gradient(circle at 150px 150px, #F39C12, #000);
}
.detailsView > .actinoid {
  background: radial-gradient(circle at 150px 150px, #ECF0F1, #000);
}

.legend {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    color: #fff;
  }
  .color {
    height: 14px;
    width: 30px;
    margin: 2px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    top: 0px;
  }
  50% {
    transform: rotate(180deg);
    top: 20px;
  }
  100% {
    transform: rotate(360deg);
    top: 0px;
  }
}

</style>
